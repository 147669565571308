export default function Custom404() {
  return (
    <div className="relative z-[200] h-screen bg-bg-black flex justify-center items-center">
      <div className="flex justify-center items-center">
        <h3 className="text-white font-light leading-none">404</h3>
        <div className="h-4 -mt-1 ml-2 mr-2 bg-white opacity-70 w-[1px]"></div>
        <p className="text-white font-light leading-none">
          This page could not be found.
        </p>
      </div>
    </div>
  );
}
